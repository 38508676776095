import React from 'react';

export default props => (
    <section className="container my-3">
        <div className="row">
            <div className="col-12 col-md-10">
                <img src={props.slice.primary.image1.url} className="w-100 mb-2"></img>
                <div className="color-grey" dangerouslySetInnerHTML={{ __html: props.slice.primary.caption.html }}></div>
            </div>
        </div>
    </section>
);
