import React from 'react';
import { getArrayHalfIndex } from '../../../utils/array';
import Styles from './advantages.module.scss';

export default props => (
    <section className="py-5">
        <div className="container">
            <div className="row">
                {/* On small screen we need to define the height */}
                <div className="col-12 col-md-5 order-md-2 mb-5 d-md-none">
                    <div className="image-bg" style={{ backgroundImage: `url(${props.slice.primary.image.url})` }}></div>
                </div>
                {/* On md+ we want to fill the height of the col */}
                <div className={`col-12 col-md-5 order-md-2 d-none d-md-block ${Styles.imageBg}`} style={{ backgroundImage: `url(${props.slice.primary.image.url})` }}>
                </div>

                <div className="col-12 col-md-7">
                    <h2 className={`m-0 text-uppercase color-green ${Styles.h2}`}>{props.slice.primary.heading.text}</h2>
                    <div className="bg-green color-white font-montserrat p-3">
                        <ul className="list-unstyled m-0">
                            {/* For small amount of items, display one column */}
                            {props.slice.primary.items.raw.length <= 5 ? props.slice.primary.items.raw.map(item => (
                                <li className="mb-2">
                                    {item.text}
                                </li>
                            )) : ''}

                            {/* For more, split into two columns */}
                            {props.slice.primary.items.raw.length > 5 ? (
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        {props.slice.primary.items.raw.slice(0, getArrayHalfIndex(props.slice.primary.items.raw)).map((item, index) => (
                                            <li key={index} className="mb-2">
                                                {item.text}
                                            </li>
                                        ))}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {props.slice.primary.items.raw.slice(getArrayHalfIndex(props.slice.primary.items.raw)).map((item, index) => (
                                            <li key={index} className="mb-2">
                                                {item.text}
                                            </li>
                                        ))}
                                    </div>
                                </div >
                            ) : ''}
                        </ul >
                    </div >
                </div >
            </div >
        </div >
    </section >

);
