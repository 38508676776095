import React from 'react';
import { getHeadingAndTextSlice } from '../../../utils/slices';
import Button from '../button/button';
import HeadingAndText from '../heading-and-text/heading-and-text';

export default props => (
    <React.Fragment>
        <HeadingAndText slice={getHeadingAndTextSlice(props.slice.primary.title.text, props.slice.primary.text.html)}/>
        <section className="container pt-2 pb-5">
            <div className="row">
                <div className="col-12">
                    <div className="position-relative">
                        <img src={props.slice.primary.image.url} alt={props.slice.primary.image.alt} className="w-100"></img>
                        <div className="container position-absolute" style={{ bottom: 0 }}>
                            <div className="row">
                                <div className="col-12 pb-5">
                                    <Button
                                        text={props.slice.primary.link_text.text}
                                        link={props.slice.primary.link.text}
                                        color="green" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
);
