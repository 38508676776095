import React from 'react';
import Button from '../button/button';
import Styles from './person-grid.module.scss';

function getPositionsMetaContent(person) {
    return person.position.raw.map(position => position.text).join(', ');
}

export default props => (
    <section className="py-5">
        <div className="container">
            {props.slice.primary && (
                <div className="row mb-4">
                    <div className="col-12 col-md-10 col-lg-8">
                        {props.slice.primary.heading && <h1 className="text-uppercase color-grey">{props.slice.primary.heading.text}</h1>}
                        {props.slice.primary.text && <div dangerouslySetInnerHTML={{ __html: props.slice.primary.text.html }}></div>}
                    </div>
                </div>
            )}
            <div className="row">
                {props.slice.items.map((person, index) => (
                    <div key={index} className="col-12 col-md-6 mb-5" itemScope itemProp="Person" itemType="https://schema.org/Person">
                        <div className="row">
                            <div className="col-12 col-sm-4 mb-2">
                                {person.image.url && <meta itemProp="image" content={person.image.url}></meta>}
                                {person.image.url && <img className="w-100" src={person.image.url} alt={person.image.alt}></img>}
                            </div>
                            <div className="col-12 col-sm-8">
                                <h2 className="color-green text-uppercase font-weight-light" itemProp="name">
                                    <span itemProp="givenName">{person.firstname.text}</span>
                                    {person.lastname.text && <span itemProp="familiyName"> {person.lastname.text}</span>}
                                </h2>
                                <meta itemProp="jobTitle" content={getPositionsMetaContent(person)}></meta>
                                <ul className="list-unstyled mb-3">
                                    {person.position.raw.map((positionLine, index) => (
                                        <li key={index} className="color-grey-light">{positionLine.text}</li>
                                    ))}
                                </ul>
                                {
                                    person.text && (
                                        <React.Fragment>
                                            {person.text.text && <small className="d-block color-green mb-2">Spezialgebiet</small>}
                                            {person.text.text && <div className={Styles.skills} dangerouslySetInnerHTML={{ __html: person.text.html }} itemProp="description"></div>}
                                            {person.phone && person.phone.text && <meta itemProp="telephone" content={person.phone.text}></meta>}
                                            {person.phone && person.phone.text && <div className={`d-block ${person.email && person.email.text ? 'mb-3' : ''}`}>
                                                <Button
                                                    text={person.firstname.text + ' anrufen'}
                                                    color="green"
                                                    small={true}
                                                    icon="phone"
                                                    link={'tel:' + person.phone.text}/>
                                            </div>}
                                            {person.email && person.email.text && <div className="d-block">
                                                <Button
                                                    text="E-Mail senden"
                                                    color="green"
                                                    small={true}
                                                    icon="email"
                                                    link={'mailto:' + person.email.text}/>
                                            </div>}
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div >
        </div >
    </section >

);
