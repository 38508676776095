import React from 'react';
import Styles from './image-links.module.scss';
import Link from '../../global/link/link';

export default props => (
    <section className="container py-5">
        <div className="row">
            {
                props.slice.items.map((item, index) => (
                    <div key={index} className={`col-12 col-md-6 mb-4 ${props.slice.items.length === 2 ? 'col-lg-6' : 'col-lg-4'}`}>
                        <Link className="unstyled" to={item.link.text}>
                            <div className={`position-relative ${Styles.item}`} style={{ backgroundImage: `url(${item.image.url})` }}>
                                <div className={Styles.overlay}></div>
                            </div>
                            <span className={`ml-2 mt-1 ${Styles.span}`} dangerouslySetInnerHTML={{ __html: item.text.text }}></span>
                        </Link>
                    </div>
                ))
            }
        </div>
    </section>

);
