import React from 'react';

export default props => (
    <section className="container py-5">
        <div className="row">
            {props.slice.items.map((item, items) => (
                <div className="col-12 col-md-6 mb-5">
                    <h2 className="color-green font-weight-light text-uppercase" dangerouslySetInnerHTML={{ __html: item.heading.text }}></h2>
                    <div dangerouslySetInnerHTML={{ __html: item.text.html }}></div>
                </div>
            ))}
        </div>
    </section>
);
