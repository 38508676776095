import React from 'react';
import Styles from './image-gallery.module.scss';

const MAX_IMAGE_AMOUNT = 6;

export default props => {
    if (props.slice.items.length > MAX_IMAGE_AMOUNT) {
        console.debug(`No image grid layout is defined for ${props.slice.items.length} images! Falling back to max amount of ${MAX_IMAGE_AMOUNT}.`);
        props.slice.items = props.slice.items.slice(0, MAX_IMAGE_AMOUNT);
    }

    return (
        <section className="py-5">
            <div className="container py-5">
                {/* Normal row/col for small screens */}
                <div className="row d-md-none">
                    {props.slice.items.map((item, index) => (
                        <div key={index} className="col-12">
                            <img src={item.image.url} className="w-100 mb-3"></img>
                        </div>
                    ))}
                </div>
                {
                    props.slice.primary?.do_not_use_grid_for_single_image && props.slice.items.length === 1 ? (
                        <div className="col-12">
                            <img alt={props.slice.items[0].image.alt} src={props.slice.items[0].image.url} className="w-100"></img>
                        </div>
                    ) : (
                    <div className="row d-none d-md-block">
                        <div className={Styles.wrapper}>
                            {props.slice.items.map((item, index) => (
                                <div key={index} className={`b b-${props.slice.items.length} b-${props.slice.items.length}-i-${index + 1}`} style={{ backgroundImage: `url(${item.image.url})` }}>
                                </div>
                            ))}
                        </div>
                    </div>
                )
              }
            </div>
        </section>
    );
}
