import React from 'react';
import Styles from './bulletpoints.module.scss';

function getRandomMargin(scale = 1) {
    return Math.random() * scale;
}

export default props => (
    <section className="py-5">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-5">
                    <ul className="list-unstyled">
                        {
                            props.slice.primary.bulletpoints.raw.map((point, index) => (
                                <li key={index}>
                                    <span className={`text-uppercase color-green d-none d-md-inline ${Styles.span}`} style={{ marginLeft: getRandomMargin(50) + 'px' }}>
                                        {point.text}
                                    </span>
                                    <span className={`text-uppercase color-green d-md-none ${Styles.span}`}>
                                        {point.text}
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="col-12 col-md-7" dangerouslySetInnerHTML={{ __html: props.slice.primary.text.html }}></div>
            </div>
        </div>
    </section>

);
