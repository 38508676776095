import React from 'react';
import BlogImage from '../../blog/image/image';
import Advantages from '../../page/advantages/advantages';
import BlogPosts from '../../page/blog-posts/blog-posts';
import Bulletpoints from '../../page/bulletpoints/bulletpoints';
import HeadingAndText from '../../page/heading-and-text/heading-and-text';
import IFrame from '../../page/iframe/iframe';
import ImageGallery from '../../page/image-gallery/image-gallery';
import ImageHeader from '../../page/image-header/image-header';
import ImageLink from '../../page/image-link/image-link';
import ImageLinks from '../../page/image-links/image-links';
import Image from '../../page/image/image';
import Paragraphs from '../../page/paragraphs/paragraphs';
import PersonGrid from '../../page/person-grid/person-grid';
import ProcessCards from '../../page/process-cards/process-cards';
import ProjectTeaser from '../../page/project-teaser/project-teaser';
import Quote from '../../page/quote/quote';
import Text from '../../page/text/text';

export default props => {
    return props.slices.map((slice, index) => {
        slice.__typename = slice.__typename
            // use special names for the following slices
            .replace('PrismicBlogPostBodyBild', '__BlogBild')
            // normalize all other slices which are identical across types
            .replace('PrismicPageBody', '')
            .replace('PrismicHomepageBody', '')
            .replace('PrismicBlogPostBody', '')
            .replace('PrismicProjectBody', '');
        {
            switch (slice.__typename) {
                case 'BildHeader':
                    return <ImageHeader key={index} slice={slice}/>;
                case 'UberschriftUndText':
                    if (index === 2) {
                        return (
                            <div style={{marginTop: '2rem'}}>
                                <HeadingAndText key={index} slice={slice}/>;
                            </div>
                        )
                    } else {
                        return <HeadingAndText key={index} slice={slice}/>;
                    }
                case 'ProzessKarten':
                    return <ProcessCards key={index} slice={slice}/>;
                case 'Vorteile':
                    return <Advantages key={index} slice={slice}/>;
                case 'Zitat':
                    return <Quote key={index} slice={slice}/>;
                case 'ProjektTeaser':
                    return <ProjectTeaser key={index} slice={slice}/>;
                case 'Bildergalerie':
                    return <ImageGallery key={index} slice={slice}/>;
                case 'PersonenBereich':
                    return <PersonGrid key={index} slice={slice}/>;
                case 'Text':
                    return <Text key={index} slice={slice}/>;
                case 'Textabschnitte':
                    return <Paragraphs key={index} slice={slice}/>;
                case 'Bild':
                    return <Image key={index} slice={slice}/>;
                case 'BilderLinks':
                    return <ImageLinks key={index} slice={slice}/>;
                case 'SchlagworteText':
                    return <Bulletpoints key={index} slice={slice}/>;
                case '__BlogBild':
                    return <BlogImage key={index} slice={slice}/>;
                case 'BildLink':
                    return <ImageLink key={index} slice={slice}/>;
                case 'BlogPosts':
                    return <BlogPosts key={index} slice={slice}/>;
                case 'Iframe':
                    return <IFrame key={index} slice={slice}/>;
                default:
                    console.log(`No slice for type ${slice.__typename}`)
                    return '';
            }
        }
    });
}
