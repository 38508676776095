import React from 'react';
import Link from '../../global/link/link';
import Styles from './process-cards.module.scss';

export default props => (
    <section className="py-5">
        <div className="container">
            <div className="row">
                {
                    props.slice.items.map((item, index) => (
                        <div key={index} className={`col-12 col-md-6 mb-4 ${props.slice.items.length === 3 ? 'col-lg-4' : ''}`}>
                          <div className={`bg-green color-white h-100 p-2 ${Styles.box}`}>
                            {item.link && item.link.text ? (
                                <Link className="unstyled" to={item.link.text}>
                                  <h2>{item.title.text}</h2>
                                  <div dangerouslySetInnerHTML={{ __html: item.text.html }}></div>
                                </Link>
                            ) : (
                              <React.Fragment>
                                <h2>{item.title.text}</h2>
                                <div dangerouslySetInnerHTML={{ __html: item.text.html }}></div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>

);
