import React from 'react';
import Styles from './iframe.module.scss';

export default props => (
    <section className={`py-5 ${Styles.section}`}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className={props.slice.primary.responsive === false ? '' : 'embed-responsive embed-responsive-16by9'}>
                        <div dangerouslySetInnerHTML={{ __html: props.slice.primary.html.text }}></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);
