import React from 'react';
import Styles from './quote.module.scss';

export default props => (
    <section style={{ backgroundImage: `url(${props.slice.primary.image.url})` }} className={`my-5 d-flex align-items-center position-relative ${Styles.section}`}>
        <div className={Styles.overlay}></div>
        <div className="container color-white">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8">
                    <div className="text-center" dangerouslySetInnerHTML={{ __html: props.slice.primary.text.html }}></div>
                    <p className={`text-right font-italic ${Styles.author}`}>{props.slice.primary.author.text}</p>
                </div>
            </div>
        </div>
    </section>
);
