import React from 'react';
import Styles from './project-teaser.module.scss';
import Button from '../button/button';

export default props => (
    <section className="py-5">
        <div className="container">
            <div className="row">
                {props.slice.items.map((project, index) => {
                  const isVideo = project.use_video || (!project.image?.url && project.video_link?.text);
                  return (
                    <div key={index} className="col-12 col-sm-6 col-lg-4 mb-5" itemScope itemType="https://schema.org/Article">
                        {
                          isVideo ?
                          (
                            <div className="embed-responsive embed-responsive-350by240 mb-3">
                                <video
                                    controls
                                    controlsList="nofullscreen"
                                    className="w-100"
                                    preload="metadata"
                                    poster={project.video_thumbnail?.url || project.image?.url}
                                >
                                    <source src={project.video_link.text} type="video/mp4"></source>
                                </video>
                            </div>
                          ) :
                          (
                            <React.Fragment>
                                <meta itemProp="image thumbnailUrl" content={project.image.url}></meta>
                                <div style={{ backgroundImage: `url(${project.image.url})` }} className={`mb-3 ${Styles.img}`}></div>
                            </React.Fragment>
                          )
                        }
                        <h2 itemProp="name headline" className={`color-green font-weight-light ${Styles.h2}`}>
                            {project.title.text}
                        </h2>
                        <div itemProp="description" dangerouslySetInnerHTML={{ __html: project.text.html }} style={{
                            marginBottom: isVideo ? '-8px' : undefined
                        }}></div>
                        {
                            isVideo ? (
                               <span className={`${Styles.videoSpan}`}>Video</span>
                            ) : ''
                        }
                        {
                            project.link?.text && project.link_text?.text ? (
                                <React.Fragment>
                                    <meta itemProp="url mainEntityOfPage" content={'ABSE' + project.link.text}></meta>
                                    <Button text={project.link_text.text} link={project.link.text} small={true} color="green"/>
                                </React.Fragment>
                            ) : ''
                        }
                    </div>
                );
                })}
            </div>
        </div>
    </section>

);
